import React from "react"

export default
    class WistiaVideo extends React.Component {



    render() {
        return (
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", top: "0", left: "0" }}>
                    <div className="wistia_embed wistia_async_xqqvstck0q videoFoam=true" />
                </div>
            </div >
        );
    };
}

