import React from "react"
import { Link } from "gatsby"
import WistiaVideo from "../../components/wistia-video"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  VideoWrapper,
} from "../../components/contentwrappers"
import BackgroundImage from "gatsby-background-image"

import FaqAccordion from "../../components/accordion/accordion"
import ActionButton from "../../components/actionbutton"

// log the user clicking the 'get started' buttons
// uses the Tinybird script that is loaded on each page via the Layout component
function logSelfServeLink(position){
  Tinybird.trackEvent('click_self_serve_link', {
    position: position
    //timestamp and session-id auto added by tinybird
  });
}


const SelfServePage = props => (
  <>
    <Helmet>
      <script
        src="https://fast.wistia.com/embed/medias/xqqvstck0q.jsonp"
        async
      ></script>
      <script
        src="https://fast.wistia.com/assets/external/E-v1.js"
        async
      ></script>
    </Helmet>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "-9",
      }}
    ></BackgroundImage>

    <Layout>
      <SEO title="Self Serve" />
      <PageWrapper>
        <HeadingWrapper>
          <h1>
            Self Serve
          </h1>
        </HeadingWrapper>
        <TextWrapper>
          <p>
            Welcome to the ‘Self Serve’ area of our website.</p>
            <p></p>
            
            <p>
            Many of our clients have very straightforward requirements or wish
            to take more responsibility for arranging their own affairs. As part
            of our commitment to helping solicitors’ clients we designed this
            service so that the fees associated with face to face regulated
            investment advice would not become a barrier or prohibitive to us
            helping solicitors to help their clients.
          </p>

          <p>
            Over time we intend to build on the services we provide in this area
            but at the moment we are delighted to be able to help you buy
            Investment ISAs and General Investment Accounts. For
            clients looking for Deposit Based accounts such as Cash ISAs or Bank
            &amp; Building Society Savings Accounts please e-mail us for our
            guide to the latest rates at{" "}
            <a href="mailto:info@lsnifa.com">info@lsnifa.com</a> or call 028
            9023 0696.
          </p>
            <p> Please
            watch this short video about investment risk and take a look at the{" "}
            <Link to="#faq">frequently asked questions</Link> section.
          </p>
        </TextWrapper>

        <VideoWrapper>
          <WistiaVideo></WistiaVideo>
        </VideoWrapper>

        <TextWrapper>
          <p>
            We currently have two investment philosophies for you to choose
            from.
          </p>

          <h2>
            <strong>Conviction</strong>
          </h2>
          <p>
            The investment industry debates the merits of active investment
            management versus passive investment management with an almost
            religious fervour. Our view is both have their relative pros and
            cons and the appropriateness of one over the other can change during
            various investment market cycles.
          </p>

          <p>
            Conviction simply means an investment manager is given the choice to
            alter the balance between the two styles on an ongoing basis as they
            see fit.
          </p>

          <h2>
            <strong>Ethical</strong>
          </h2>
          <p>
            Ethically influenced purchasing decisions are now firmly established
            as part of everyday life. The use of labels such as ‘fair trade’,
            ‘organic’, ‘ethically traded’, ‘recycled’, ‘sustainable’ and ‘low
            carbon emissions’ is on the increase, as both businesses and
            individuals recognise their benefits.
          </p>

          <p>
            Investors’ interest in Ethical investing has also been on the
            increase. Recognising that not all ethical investors will have the
            same preferences we have four ‘flavours’ for you to choose from.
          </p>

          <div style={{ textAlign: "center" }}>
            <span onClick={()=>logSelfServeLink('first')}>
              <ActionButton
              
              external
              toRef="https://lawsociety.parmenion.co.uk/client/Application/gettingstarted/BeforeWeStart"
              
            >
              Get <strong>Started</strong>
              
              </ActionButton>
            </span>
          </div>
        </TextWrapper>

        <TextWrapper id="faq">
          <h2>
            Frequently asked <strong>questions</strong>
          </h2>
          <FaqAccordion></FaqAccordion>

          <div style={{ textAlign: "center" }}>
          <span onClick={()=>logSelfServeLink('second')}>
            <ActionButton
              external
              toRef="https://lawsociety.parmenion.co.uk/client/Application/gettingstarted/BeforeWeStart"
              onClick={()=>logSelfServeLink('second')}
            >
              Get <strong>Started</strong>
            </ActionButton>
            </span>
          </div>

          <div style={{ textAlign: "center" }}>
            <ActionButton toRef="/contact">
              <strong>Contact Us</strong> to find out more...
            </ActionButton>
          </div>
        </TextWrapper>

       
      </PageWrapper>
    </Layout>
  </>
)

export default SelfServePage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(
      relativePath: { eq: "royal-courts-of-justice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5031, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
