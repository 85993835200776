import React from "react"
import { Link } from "gatsby"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import "./style.css"

const FaqAccordion = () => {
  return (
    <Accordion>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            1. Can I speak to a Planner?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            If you need to speak to a planner at any stage of the process, you
            can contact us for assistance. You can arrange a telephone call or
            have an email conversation – whichever suits. If it is possible, we
            can arrange to meet in person.
          </p>
          <p>
            If you start the process but want to speak to us before completing,
            ensure you go as far as creating an email and password so that you
            can log back in to where you left off.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>2. What does it cost?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We charge an ongoing fee of 0.5% pa of assets under management. For
            example, if you invested £1000 we are paid £5 annually.
          </p>
          <p>
            Parmenion will take an initial fee of 0.25% of your contributions
            and 0.3% ongoing for managing the investment. So again using the
            same £1000 investment, this would be an initial fee of £2.50 and an
            ongoing fee of £3 annually.
          </p>
          <p>
            You can also get a FREE risk profile and investment proposal without
            committing to anything.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>3. What is an ISA?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            An Individual Savings Account (ISA) is simply an investment account
            on which NO TAX IS PAYABLE. This means that it can grow completely
            free of tax, apart from 10% dividend tax which cannot be reclaimed.
          </p>
          <p>NB: You pay tax on income in a normal bank account.</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>4. What is a GIA?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            A General Investment Account is an investment account on which TAX
            IS PAYABLE on any income or growth.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>5. How much can I invest?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            In an ISA, you have an annual ISA Allowance, which is currently
            £20,000 per annum for the tax year 2022/23. You cannot carry this
            forward, so you either use it or lose it.
          </p>
          <p>There is no limit as to how much you can invest in a GIA.</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            6. Is there a minimum amount I need to invest?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            The minimum investment is a one off £1,000 lump sum or a monthly
            contribution of £100.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>7. Can I withdraw my money?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Yes – after all, it’s your money. You can log in to your account and
            request a full or partial withdrawal. It can take up to two weeks
            for your money to be dis-invested and sent to your bank account.
            Please remember that it may not be worth as much as you put in, as
            investments can go down as well as up.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>8. Am I locked in?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            No. However, we view investing as a long term game, and so if you
            have plans for this money or think you will need it within 3-5
            years, we would advise against investing. Similarly, there is no
            time period for you to change your investments, so this money could
            be invested for the rest of your life.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>9. Do you offer pension?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Please see the{" "}
            <Link to="/self-serve/pension">Self Serve Pension</Link> section of
            our website.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            10. What is a simplified advice service
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Simplified advice is a streamlined advice process aiming to address
            straightforward needs of clients. It is a limited form of advice, in
            that it is focused on one or more specific needs without involving a
            full analysis of client circumstances if they are not directly
            relevant.
          </p>
          <p>
            The outcome of the simplified advice process is to make a specific
            recommendation, provided it’s suitable, based on the information
            provided by you. It is therefore important to be honest and accurate
            in the answers you give to ensure you receive an accurate
            recommendation.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            11. Can I transfer existing investments into this service?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We are happy to accept transfers (such as transfer of an existing
            ISA). However, due to the limitations of this being an online advice
            process we are NOT able to make recommendations on which assets you
            should sell or transfer. This will be solely down to your
            discretion. If you are unsure, please contact us.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            12. How is this different to other online services?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Most online services do not provide advice based upon your personal
            circumstances. They are known as ‘execution only’ services. You
            therefore carry the risk of investing in funds that do not perform
            within your tolerance for risk and potentially expose you to losses
            that you do not have the capacity to accept. Also, if you get it
            wrong you have no form of redress. We go to great lengths to make a
            suitable recommendation for you and our advice is covered under the
            Financial Ombudsman service.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            13. What is the online investment service designed to do?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            This service helps you by providing advice to match your investment
            objectives to a specific risk level, selected by asking you
            questions about your risk tolerance (your personal acceptance of
            risk) and your risk capacity (your ability to accept loss).
          </p>
          <p>
            You will be guided through a process that gathers relevant details
            about your investment amounts and timeframe and then a process to
            assess what risk level is right for your investment. Then, we will
            provide you with a comprehensive investment management proposal that
            outlines the investment portfolio that we are proposing to build for
            you based upon what we have assessed to be the right risk level for
            you.
          </p>
          <p>
            If you wish to proceed and set up an ISA and/or GIA you can do so
            online. At this stage we will need to ask you some personal
            questions about your financial situation and after investing we will
            provide you with a full suitability report confirming the parameters
            of this service and why it is right for you.
          </p>
          <p>
            If we feel like that the investment is not suitable for you, or some
            information you give makes us think that this may not be the best
            use of your money, we will contact you either by phone or email, to
            talk through the issue and explain what will happen next.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            14. Can I invest if I am not a UK resident?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            No – our services are available to UK residents only. This means as
            long as you are a UK resident for tax purposes you are eligible to
            invest with us.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            15. When can I review my investments?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            At any time. You can log in to see how your investment is doing when
            it suits you. Once you have been invested for 1 year, Parmenion will
            automatically send out an Investor 6 Monthly Report.
          </p>
          <p>
            You can retake the risk questionnaire if you wish to change your
            risk profile, and you can also manage your contributions whenever
            and as often as you like.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            16. How can I pay money into my account?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            The easiest way is to set up a direct debit or bank transfer from
            your bank account directly to Parmenion. They will also pay any
            withdrawals directly into your bank account.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            17. How is my money invested?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We want you to understand the commitment you are making before you
            invest. We therefore provide a personalised investment proposal part
            way through the process to outline the parameters, potential
            volatility characteristics and expected return from your investment.
            You can go through the process until you reach this Investment
            Proposal for free with no obligation. It is then up to you whether
            or not you decide to continue.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            18. What do I do if I have a complaint?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Law Society (NI) Financial Advice Ltd has a strict complaints policy
            to resolve any issues quickly and efficiently. Please refer to our{" "}
            <Link to="/complaints-policy">Complaints Policy</Link> if you have a
            complaint about our services or products.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default FaqAccordion
